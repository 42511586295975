import React from 'react';
import { useIntl } from 'react-intl';

const Footer = () => {
    const intl = useIntl();
    const [navbarOpen, setNavbarOpen] = React.useState(false);
    return (
      <>
      <div className="container my-24 px-6 mx-auto">
      <section className="mb-32 text-gray-800 text-center">
        <div className="px-6 py-12 md:px-12">
          <div className="container mx-auto xl:px-32">
            <div className="grid lg:grid-cols-2  items-center">
              <div className="md:mt-12 lg:mt-0 mb-12 lg:mb-0">
                <div
                  className="block rounded-lg shadow-lg px-6 py-12 md:px-12 lg:-mr-14"
                  style={{background: "hsla(0, 0%, 100%, 0.55)", backdropFilter: "blur(30px)"}}
                >
                <h2 className="text-3xl font-bold mb-12">Contact us</h2>
                <form>
                  <div className="form-group mb-6">
                    <input
                      type="text"
                      className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      id="exampleInput7"
                      placeholder="Name"
                    />
                  </div>
                  <div className="form-group mb-6">
                    <input
                      type="email"
                      className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      id="exampleInput8"
                      placeholder="Email address"
                    />
                  </div>
                  <div className="form-group mb-6">
                    <textarea
                      className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      id="exampleFormControlTextarea13"
                      rows="3"
                      placeholder="Message"
                    ></textarea>
                  </div>
                  <div className="form-group form-check text-center mb-6">
                    <input
                      type="checkbox"
                      className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain mr-2 cursor-pointer"
                      id="exampleCheck87"
                      checked
                    />
                    <label className="form-check-label inline-block text-gray-800" for="exampleCheck87">
                      Send me a copy of this message
                    </label>
                  </div>
                  <button
                    type="submit"
                    className="w-full px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                  >
                    Send
                  </button>
                </form>
                </div>
              </div>
              <div className="md:mb-12 lg:mb-0">
                <div style={{height: "700px", width: "700px", zIndex: -1}} className="map-container relative shadow-lg rounded-lg">
                  <iframe src="https://maps.google.com/maps?q=Neptune%27s%20Fountain%2C%20Long%20Market%2C%20Gdansk&t=&z=13&ie=UTF8&iwloc=&output=embed" className="left-0 top-0 h-full w-full absolute rounded-lg" frameborder="0" allowfullscreen></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </div>

<nav className="relative flex flex-wrap items-center justify-between px-2 py-3 bg-blue-500 mb-3">
<div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
  <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
    <a
      className="text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase text-white"
      href="/"
    >
      Regeneracja
    </a>
    <button
      className="text-white cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
      type="button"
      onClick={() => setNavbarOpen(!navbarOpen)}
    >
      <i className="fas fa-bars"></i>
    </button>
  </div>
  <div
    className={
      "lg:flex flex-grow items-center" +
      (navbarOpen ? " flex" : " hidden")
    }
    id="example-navbar-danger"
  >
    <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
      <li className="nav-item">
        <a
          className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75"
          href="#pablo"
        >
          <i className="fab fa-facebook-square text-lg leading-lg text-white opacity-75"></i><span className="ml-2">Share</span>
        </a>
      </li>
      <li className="nav-item">
        <a
          className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75"
          href="#pablo"
        >
          <i className="fab fa-twitter text-lg leading-lg text-white opacity-75"></i><span className="ml-2">Tweet</span>
        </a>
      </li>
      <li className="nav-item">
        <a
          className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75"
          href="#pablo"
        >
          <i className="fab fa-pinterest text-lg leading-lg text-white opacity-75"></i><span className="ml-2">Pin</span>
        </a>
      </li>
    </ul>
  </div>
</div>
</nav>
</>
    );
}

export default Footer;
