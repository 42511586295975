import React from 'react';
import { IntlProvider } from 'react-intl';
import messages_pl from './config/locale/pl.json';
import messages_en from './config/locale/en.json';
import messages_be from './config/locale/be.json';
import { AppRoutes } from './core/routes/AppRoutes';
import { AppLayout } from './layout/AppLayout';

import { first } from "lodash";

import "@fortawesome/fontawesome-free/css/all.min.css";
import styles from "./index.css";

import { ThemeProvider } from "@material-tailwind/react";

const messages = {
  'pl': messages_pl,
  'en': messages_en,
  'be': messages_be
};

function App() {
  const language = first(navigator.language.split(/[-_]/)) || "pl";

  return (
    <ThemeProvider>
      <IntlProvider locale={language} messages={messages[language]}>
        <AppLayout>
          <AppRoutes/>
        </AppLayout>
      </IntlProvider>
    </ThemeProvider>
  );
}

export default App;
