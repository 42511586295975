import React from 'react';
import { useIntl } from 'react-intl';

export const Cards = () => {
    const intl = useIntl();

    return (
      <div class="px-6 py-12 md:px-12 text-gray-800 text-center lg:text-left grid grid-cols-1 gap-1 md:grid-cols-4 md:gap-4">
        <div class="max-w-sm rounded overflow-hidden shadow-lg  mb-4">
            <img class="object-cover h-48 w-96 " src="https://mdbootstrap.com/img/new/ecommerce/vertical/001.jpg" alt="Sunset in the mountains"/>
            <div class="px-6 py-4">
                <div class="font-bold text-xl mb-2">Watch</div>
                <p class="text-gray-700 text-base">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus quia, nulla! Maiores et perferendis eaque, exercitationem praesentium nihil.
                </p>
            </div>
        </div>
        <div class="max-w-sm rounded overflow-hidden shadow-lg mb-4">
            <img class="object-cover h-48 w-96 " src="https://mdbootstrap.com/img/new/ecommerce/vertical/002.jpg" alt="Sunset in the mountains"/>
            <div class="px-6 py-4">
                <div class="font-bold text-xl mb-2">Mobile Phone</div>
                <p class="text-gray-700 text-base">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus quia, nulla! Maiores et perferendis eaque, exercitationem praesentium nihil.
                </p>
            </div>
        </div>
        <div class="max-w-sm rounded overflow-hidden shadow-lg mb-4">
            <img class="object-cover h-48 w-96 " src="https://mdbootstrap.com/img/new/ecommerce/vertical/003.jpg" alt="Sunset in the mountains"/>
            <div class="px-6 py-4">
                <div class="font-bold text-xl mb-2">Tablet</div>
                <p class="text-gray-700 text-base">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus quia, nulla! Maiores et perferendis eaque, exercitationem praesentium nihil.
                </p>
            </div>
        </div>
        <div class="max-w-sm rounded overflow-hidden shadow-lg mb-4">
            <img class="object-cover h-48 w-96 " src="https://mdbootstrap.com/img/new/ecommerce/vertical/004.jpg" alt="Sunset in the mountains"/>
            <div class="px-6 py-4">
                <div class="font-bold text-xl mb-2">Notebooks</div>
                <p class="text-gray-700 text-base">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus quia, nulla! Maiores et perferendis eaque, exercitationem praesentium nihil.
                </p>
            </div>
        </div>

      </div>
    )
};
