import React from 'react';
import { useIntl } from 'react-intl';

import { Select, Option, Typography } from "@material-tailwind/react";

// import prices from "./prices-data.json";

const data = {
  "Apple": [
    "iPhone 4 (GSM)",
    "iPhone 4 (CDMA)",
    "iPhone 5",
    "iPhone 5C",
    "iPhone 5S",
    "iPhone 6S Plus",
    "iPhone 7",
    "iPhone 8",
    "iPhone X",
    "iPhone XR",
    "iPhone XS",
    "iPhone 11",
    "iPhone SE",
    "iPhone 12",
    "iPhone 13",
    "iPhone SE",
    "iPhone 15"
  ]
}

export const PricePage = () => {
    const intl = useIntl();

    return (
      <>
        <Typography color="blueGray" className="text-center text-4xl font-bold mt-24 mb-12">
          Select your phone and mode
        </Typography>
        <div className="flex flex-wrap mb-6 max-w-lg mx-auto">
          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
          <Select label="Producer" className=" mr-6">
          <Option>Apple</Option>
          <Option>LG</Option>
          <Option>Xiomi</Option>
          <Option>...</Option>
        </Select>

          </div>
          <div className="w-full md:w-1/2 px-3">
          <Select label="Model" className=" mr-6">
          {data.Apple.map((item) => <Option>{item}</Option>)}
        </Select>
          </div>
        </div>

        <div className="container my-24 px-6 mx-auto">
            <section className="mb-32 text-gray-800 text-center">
                <div className="px-6 py-12 md:px-12">
                <div className="container mx-auto xl:px-32">
                    <div className="grid lg:grid-cols-2 flex items-center">
                    <div className="md:mt-12 lg:mt-0 mb-12 lg:mb-0">
                        <div
                        className="block rounded-lg shadow-lg px-6 py-12 md:px-12 lg:-mr-14"
                        style={{background: "hsla(0, 0%, 100%, 0.55)", backdropFilter: "blur(30px)"}}
                        >
                        <h2 className="text-3xl font-bold mb-12">
                            Trusted by the best companies <br />
                            <span className="">around the world</span>
                        </h2>
                        <div className="grid md:grid-cols-2 gap-x-6">
                            <div className="mb-12">
                              Replacement of screen with original parts

                              <p class="flex flex-col text-3xl font-bold">
                                  $99
                              </p>
                            </div>

                            <div className="mb-12">
                              Replacement of screen with substitute parts

                              <p class="flex flex-col text-3xl font-bold">
                                  $99
                              </p>
                            </div>

                            <div className="mb-12 md:mb-0">
                              Replacement of batary

                              <p class="flex flex-col text-3xl font-bold">
                                  $99
                              </p>
                            </div>

                            <div className="">
                              Renovation of phone

                              <p class="flex flex-col text-3xl font-bold">
                                  $99
                              </p>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="md:mb-12 lg:mb-0">
                        <img
                        src="https://mdbootstrap.com/img/new/ecommerce/vertical/029.jpg"
                        className="w-full rounded-lg shadow-lg"
                        alt=""
                        />
                    </div>
                    </div>
                </div>
              </div>
          </section>
        </div>
      </>
    );
}

export default PricePage;
