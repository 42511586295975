import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import MainPage from "../../pages/MainPage"
import PricePage from "../../pages/PricePage"
import RepairServicePage from "../../pages/RepairServicePage"
import ServicesPage from "../../pages/ServicesPage"
import HowToGetToUsPage from "../../pages/HowToGetPage"
import AboutUsPage from "../../pages/AboutUsPage"

export const AppRoutes = () => (

    <BrowserRouter>
        <Routes>
          <Route path="/" element={<MainPage/>}/>
          <Route path="/price" element={<PricePage/>} />
          <Route path="/repair-service" element={<RepairServicePage/>} />
          <Route path="/services" element={<ServicesPage/>} />
          <Route path="/how-to-get-to-us" element={<HowToGetToUsPage/>} />
          <Route path="/about-us" element={<AboutUsPage/>} />
        </Routes>
    </BrowserRouter>

);

export default AppRoutes;
