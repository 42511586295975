import Header from "./components/Header";
import Footer from "./components/Footer";

export const AppLayout = ({ children }) => {
    console.log({children});
    return (
        <>
            <Header />
            <main>{children}</main>
            <Footer />
        </>
    );
}

export default AppLayout;
