import React from 'react';
import { useIntl } from 'react-intl';

const HowToGetPage = () => {
    const intl = useIntl();

    return null;
}

export default HowToGetPage;
